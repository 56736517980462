<template>
  <div>
    <section class="jewelry-platform-section">
      <div class="section-content mx-auto">
        <div class="jewelry-platform-info">
          <h1 class="section-header">
            The <span class="marker-mark--font light-blue--text">jewelry</span>
            <br />
            they want, on the <br />
            <span class="marker-mark--font light-blue--text">platform</span>
            you need
          </h1>
          <ValidationObserver v-slot="{ handleSubmit }" tag="div">
            <v-form
              @submit.prevent="handleSubmit(submitLoginForm)"
              class="jewelry-platform-email-form"
            >
              <ValidationProvider
                mode="eager"
                tag="label"
                name="Email"
                rules="required|email"
                v-slot="{ errors }"
              >
                <v-text-field
                  outlined
                  type="email"
                  hide-details="auto"
                  v-model="email"
                  color="black"
                  class="mt-4 mt-md-9 jewelry-platform-email-input"
                  placeholder="Enter your email address"
                ></v-text-field>
                <div class="error--text text-left mt-2">{{ errors[0] }}</div>
              </ValidationProvider>

              <v-btn
                dark
                depressed
                type="submit"
                height="auto"
                color="#F20D55"
                class="mt-4 mt-md-5 px-4 py-2 submit-btn"
                style="font-size: 18px; line-height: 22px;"
              >
                <img
                  class="mr-2"
                  width="24"
                  height="24"
                  :src="require('@/assets/img/icons/ic-arrow-right-white.svg')"
                  alt=""
                />
                Start
              </v-btn>
            </v-form>
          </ValidationObserver>
        </div>
      </div>

      <img
        :src="require('@/assets/img/landings/main-page/woman-1.png')"
        alt=""
      />
    </section>

    <section class="collaborate-section">

      <h2 class="section-header text-center">How to collaborate with us:</h2>

      <div class="section-content d-flex flex-wrap align-start justify-center mt-12">
        <div class="collaborate-section-item">
          <h5 class="marker-mark--font light-blue--text text-capitalize font-weight-regular">
            Join
            <img src="@/assets/img/landings/underline.svg" class="underline">
          </h5>
          <p class="text-uppercase mt-2 mb-6">
            the Roxie community <br />
            for free
            <img
                class="ml-n2 laptop"
                :src="require('@/assets/img/landings/main-page/laptop.svg')"
              />
          </p>
        </div>
        <div class="collaborate-section-item">

          <div class="right-jewelry">
            <img src="@/assets/img/landings/remaster/collaborate-right-jewelry.svg" class="main-jewelry">
            <img src="@/assets/img/landings/remaster/collaborate-right-jewelry-path-1.svg" class="path-1">
            <img src="@/assets/img/landings/remaster/collaborate-right-jewelry-path-2.svg" class="path-2">
          </div>

          <h5 class="marker-mark--font light-blue--text text-capitalize font-weight-regular mb-7 discover-title">
            <span class="marker-mark--font light-blue--text font-weight-regular">
              Discover
            </span>
            <br />
            & Customize
          </h5>

          <img src="@/assets/img/landings/underline.svg" class="underline discover-undescore">

          <div class="description-wrapper">
            <p class="text-uppercase">
              jewelry from our <br /> catalogue
            </p>
            <div class="left-jewelry">
              <img src="@/assets/img/landings/remaster/collaborate-left-jewelry.svg" class="main-jewelry">
              <img src="@/assets/img/landings/remaster/collaborate-right-jewelry-path-1.svg" class="path-1">
              <img src="@/assets/img/landings/remaster/collaborate-right-jewelry-path-2.svg" class="path-2">
            </div>
          </div>
        </div>
        <div class="collaborate-section-item">
          <h5 class="marker-mark--font light-blue--text text-capitalize font-weight-regular">
            Add
            <img src="@/assets/img/landings/underline.svg" class="underline">
          </h5>

          <img src="@/assets/img/landings/main-page/plane.svg" class="plane">

          <p class="text-uppercase mt-1">
            them to your <br />
            online store
          </p>
        </div>
      </div>
    </section>

    <section class="section-products">
      <h2 class="section-header mb-8 mb-sm-13 text-center">
        Authentically bespoke jewelry, <br />
        customized to
        <div class="shine-wrapper">
          <img src="@/assets/img/landings/main-page/shine.svg">
        </div>
      </h2>

      <div class="section-content pt-4 text-center">
        <Products class="mb-10" :itemsCount="14"></Products>
        <v-btn
          depressed
          outlined
          light
          :height="37"
          color="grey-4"
          class="px-6 py-4 see-all-products-btn"
          :to="{ name: 'CatalogLanding' }"
        >
          <img
            class="mr-2"
            width="24"
            height="24"
            :src="require('@/assets/img/icons/ic-arrow-right-pink.svg')"
            alt=""
          />
          See all products
        </v-btn>
      </div>
    </section>

    <section class="section-widget pt-13 px-4 text-center">
      <h2 class="section-header mx-auto mb-4 mb-sm-12">
        Top-notch configurations <br>for a <span class="d-inline-block marker-mark--font light-blue--text">Personalized Fit</span>
      </h2>
      <img src="@/assets/img/landings/main-page/widget.png" />
    </section>

    <section class="section-money text-center">
      <h2 class="section-header mb-11">
        Making money is
        <span class="d-block mx-auto marker-mark--font light-blue--text">straightforward and easy</span>
        with Roxie.
      </h2>


      <div class="section-content mx-auto">
        <div class="section-money-widget mx-auto">
          <div class="widget-background-left">
            <div class="bar">
              <img src="@/assets/img/landings/remaster/making-money-brick-background.svg">
            </div>
            <div class="bar">
              <img src="@/assets/img/landings/remaster/making-money-brick-background.svg" v-for="i of 3" :key="i">
            </div>
            <div class="bar">
              <img src="@/assets/img/landings/remaster/making-money-brick-background.svg" v-for="i of 6" :key="i">
            </div>
            <div class="bar">
              <img src="@/assets/img/landings/remaster/making-money-brick-background.svg" v-for="i of 4" :key="i">
            </div>
          </div>
          <div class="rhombus"></div>
          <div class="widget-content">
            <div class="section-money-widget-row pb-6 pb-sm-3 d-flex align-center justify-space-between text-left">
              Your Average Purchase Cost
              <div class="ml-8 text-center">
                <input
                  v-model="averagePurchaseCost"
                  @change="validateNumber($event, 'averagePurchaseCost')"
                  @keyup="validateNumber($event, 'averagePurchaseCost')"
                  class="flama--font text-center"
                />
                <span>
                  <strong class="d-inline-block font-weight-regular">$</strong>
                  <b class="font-weight-regular">{{ averagePurchaseCost }}</b>
                </span>
              </div>
            </div>
            <div class="section-money-widget-row pb-6 pb-sm-3 d-flex align-center justify-space-between text-left">
              Set Your Markup
              <div class="ml-8 text-center">
                <input
                  v-model="markupPercent"
                  @change="validatePercent"
                  @keyup="validatePercent"
                  class="flama--font text-center"
                />
                <span>
                  <b class="font-weight-regular">{{ markupPercent }}</b>
                  <strong class="d-inline-block font-weight-regular">%</strong>
                </span>
              </div>
            </div>
            <div class="section-money-widget-row pb-6 pb-sm-3 d-flex align-center justify-space-between text-left">
              Quantity Sold
              <div class="ml-8 text-center">
                <input
                  v-model="quantitySold"
                  @change="validateNumber($event, 'quantitySold')"
                  @keyup="validateNumber($event, 'quantitySold')"
                  class="flama--font text-center"
                />
              </div>
            </div>
            <div class="section-money-widget-row mt-2 pb-6 pb-sm-3 d-flex align-center justify-space-between text-left">
              Your Average Sell Price
              <div class="ml-8 text-center">${{ formatPrice(sellPrice) }}</div>
            </div>

            <div class="section-money-widget-row pb-6 pb-sm-3 d-flex flex-wrap align-center justify-space-between text-left font-weight-bold">
              Your Profit
              <div class="ml-auto text-center">${{ formatPrice(profit) }}</div>
            </div>
            <v-btn
              dark
              depressed
              type="submit"
              height="auto"
              color="black"
              class="mt-md-12 px-6 py-4 btn"
              style="font-size: 18px !important;"
              :to="{ name: 'Register' }"
            >
              <img
                class="mr-2"
                width="24"
                height="24"
                :src="require('@/assets/img/icons/ic-arrow-right-white.svg')"
                alt=""
              />
              Start selling
            </v-btn>
          </div>
          <div class="widget-background-right"></div>
        </div>
      </div>
    </section>

    <section class="testimonials-section">
      <section class="business-section text-center">
        <h2 class="section-header mx-auto">
          Starting your jewelry business <br>
          is a
          <div class="snap-wrapper">
            <img src="@/assets/img/landings/remaster/snap-graphick.svg">
          </div>
          with Roxie.
        </h2>
        <img :src="require('@/assets/img/landings/remaster/start-your-buisness.svg')"/>
      </section>

      <div class="section-content" id="testimonials">

        <div class="feedback-container">
          <h2 class="section-header mx-auto text-left">
            Don’t just take
            <span class="marker-mark--font light-blue--text">our word</span> for it
            <br>—take it from our customers too!
          </h2>
          <div class="feedbacks-list">
            <div class="feedbacks-row d-flex">
              <div class="item d-flex">

                <div class="feedback-content d-flex">
                  <div class="feedback-content-img-wrap text-center">
                    <img class="mb-4" src="@/assets/img/landings/main-page/avatar-2.png">
                    <span class="d-block cochin-lt--font font-weight-bold">Lucy</span>
                    <p class="mt-1 cochin-lt--font">New York, NY</p>
                  </div>
                  <div class="text cochin-lt--font">
                    The app is easy to use, and I was able to quickly add jewelry to my own e-store and begin selling. Forget about managing orders and inventory, it’s all handled for you! I highly recommend this app to anyone with a shopify store; selling jewelry has never been this easy!
                  </div>
                </div>

              </div>
              <div class="item d-flex second">
                <div class="feedback-content d-flex">
                  <div class="feedback-content-img-wrap text-center">
                    <img class="mb-4" src="@/assets/img/landings/main-page/avatar-1.png">
                    <span class="d-block cochin-lt--font font-weight-bold">Veronica</span>
                    <p class="mt-1 cochin-lt--font">Los Angeles, CA</p>
                  </div>
                  <div class="text cochin-lt--font">
                    My customers were extremely delighted with the quality of jewelry. The promise of top-notch, curated jewelry was more than fulfilled; all jewelry is carefully handcrafted to fine detail. I highly recommend this app to anyone with a shopify store; selling jewelry has never been this easy!
                  </div>
                </div>
              </div>
            </div>
            <div class="feedbacks-row d-flex">
              <div class="item third">
                <div class="feedback-content d-flex">
                  <div class="feedback-content-img-wrap text-center">
                    <img class="mb-4" src="@/assets/img/landings/main-page/avatar-3.png">
                    <span class="d-block cochin-lt--font font-weight-bold">Alanis</span>
                    <p class="mt-1 cochin-lt--font">Austin, TX</p>
                  </div>
                  <div class="text cochin-lt--font">
                    The “quick add” feature on Roxie made it extremely easy for me to add jewelry to my e-store. You simply select your chosen jewelry and it will be added to your e-store for you!
                  </div>
                </div>
              </div>
              <div class="item fourth">
                <div class="feedback-content d-flex">
                  <div class="feedback-content-img-wrap text-center">
                    <img class="mb-4" src="@/assets/img/landings/main-page/avatar-4.png">
                    <span class="d-block cochin-lt--font font-weight-bold">Maria</span>
                    <p class="mt-1 cochin-lt--font">Columbus, OH</p>
                  </div>
                  <div class="text cochin-lt--font">
                    There is such a wide variety of jewelry offered, I was pleasantly surprised! From pendants to earrings, diamond to color stone accessories, as well as engraving selection for various products, your jewelry will truly feel personalized to you!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="stats-wrap d-flex flex-wrap align-end justify-center">
          <div
            class="stats-item text-center"
            v-for="(item, idx) in stats"
            :key="idx"
          >
            <img :src="require(`@/assets/img/icons/${item.icon}.svg`)" alt="" />
            <h6 class="grey-1--text my-4">{{ item.value }}</h6>
            <p class="grey-3--text mb-0">{{ item.type }}</p>
            <span>{{ item.status }}</span>
          </div>
        </div>
      </div>
    </section>

    <section class="trending-section px-11 pt-11 pb-11">
      <div class="section-content text-center mx-auto d-flex">
        <img class="roxie-box" width="214" :src="require('@/assets/img/landings/remaster/roxie-box.svg')"/>
      <div class="text-content">
        <h2 class="section-header white--text">
          #TRENDING: RRGR0080 was just ordered from New York, New York!
        </h2>
        <div class="trending-section-order d-flex justify-start mt-4">
          <img class="mr-4" width="53" :src="require('@/assets/img/landings/main-page/bag.svg')" alt=""/>
          <div class="white--text pt-2">
            San Luis Opisbo, CA
            <p class="mt-1 mb-0 grey-3--text">23 second ago</p>
          </div>
        </div>
      </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import Products from "@/components/landings/Products";

extend("email", {
  ...email,
  message: "{_field_} is not a valid email address",
});

extend("required", {
  ...required,
  message: "{_field_} cannot be blank.",
});

export default {
  name: "MainPageLanding",
  components: {
    ValidationObserver,
    ValidationProvider,
    Products,
  },
  data() {
    return {
      email: this.$store.state.auth.userEmail,
      stats: [
        {
          icon: "ic-bag-in-the-box",
          value: "100+",
          type: "Participating",
          status: "Stores",
        },
        {
          icon: "ic-money-bag",
          value: "3,237",
          type: "Products",
          status: "Sold",
        },{
          icon: "ic-ring-solid-pink",
          value: "212",
          type: "Products",
          status: "Available",
        },
      ],
      averagePurchaseCost: 100,
      markupPercent: 100,
      quantitySold: 10,
    };
  },
  computed: {
    sellPrice() {
      const price = Math.round(this.averagePurchaseCost + (this.averagePurchaseCost * (this.markupPercent / 100)));
      return isNaN(price) ? 0 : price;
    },
    profit() {
      const profit = (this.sellPrice * this.quantitySold) - (this.averagePurchaseCost * this.quantitySold);
      return isNaN(profit) ? 0 : profit;
    },
  },
  methods: {
    submitLoginForm() {
      this.$store.commit("SET_USER_EMAIL", this.email);
      this.$router.push({ name: "Register" });
    },
    formatPrice(price) {
      return price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },
    validatePercent() {
      if (this.markupPercent.toString().charAt(0) === "0") {
        this.markupPercent = this.markupPercent.substring(1, 3);
      }

      if (/[^0-9]/g.test(this.markupPercent)) {
        this.markupPercent = this.markupPercent
          .replace(/[^0-9]/g, "")
          .substring(0, 3);
      }

      if (parseInt(this.markupPercent) > 999) {
        this.markupPercent = 999;
      }

      this.markupPercent = this.markupPercent.toString().substring(0, 3);
    },
    validateNumber($event, key) {
      if ($event.target.value.toString().charAt(0) === '0') {
        $event.target.value = $event.target.value.substring(1, 7);
      }

      if (/[^0-9]/g.test($event.target.value)) {
        $event.target.value = $event.target.value.replace(/[^0-9]/g, '').substring(0, 7);
      }

      $event.target.value = $event.target.value.toString().substring(0, 7);
      this[key] = $event.target.value;
    },
    help() {
      document.getElementById('launcher').contentWindow.document.body.querySelector('button').click();
    }
  },
};
</script>

<style scoped lang="scss">
.jewelry-platform-info {
  margin-top: 66px;
}

.section-header {
  span {
    margin-left: 13px;
  }
}
</style>
