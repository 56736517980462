<template>
  <div class="products">
    <v-progress-circular
      v-if="loading"
      class="mb-16"
      :size="150"
      width="8"
      color="primary"
      indeterminate
    ></v-progress-circular>

    <div v-if="!loading">
      <v-radio-group
        v-model="selected"
        row
        class="products-filters mx-auto mb-2 mb-sm-8"
      >
        <v-radio
          v-for="(filter, idx) in filters"
          :key="idx"
          :value="filter.val"
          :class="[
            'products-filters-item py-1 py-md-1 px-2 px-md-5 mx-2 mx-md-3 mb-3 mb-md-4',
            { 'is-active': filter.val === selected },
          ]"
        >
          <template v-slot:label>
            <div class="d-flex align-center">
              <img
                v-if="filter.val === selected"
                class="mr-3"
                width="34"
                :src="require('@/assets/img/icons/ic-ring-white.svg')"
                alt=""
              />
              <img
                v-else
                class="mr-1 mr-sm-3"
                :src="require('@/assets/img/icons/ic-ring-pink.svg')"
                alt=""
              />
              <span class="text-uppercase">{{ filter.name }}</span>
            </div>
          </template>
        </v-radio>
      </v-radio-group>

      <div class="products-wrap d-flex flex-wrap justify-center wrapper-product">
        <router-link
          class="product-card"
          v-for="product in filteredProducts"
          :key="product.id"
          :to="{
            name: 'ProductItem',
            params: { id: product.company_product_id },
          }"
        >
          <div class="preview">
            <img :src="product.image" alt="" />
            <div class="border"></div>
          </div>

          <div class="name">{{ product.name }}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Products",
  props: {
    itemsCount: {
      type: Number,
      default: 14,
    },
  },
  data() {
    return {
      loading: true,
      selected: "all",
      filters: [{ name: "All types", val: "all" }],
      products: [],
    };
  },
  async created() {
    await this.$store.dispatch("LOAD_CATEGORIES");
    await this.$store.dispatch("LOAD_PRODUCTS");
    this.filters.push(
      ...this.$store.state.products.allCategories.map((category) => ({
        name: category.name,
        val: category.id,
      }))
    );
    this.products = this.$store.state.products.allProducts.filter(
      (item, idx) => {
        if (idx < this.itemsCount) return item;
      }
    );
    this.loading = false;
  },
  computed: {
    filteredProducts() {
      return this.products.filter((product) => {
        if (this.selected === "all") return product;
        if (this.selected === product.category_id) return product;
      });
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";

.product-card {
  background: #ffffff;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: 148px;
  text-decoration: none;
  margin-bottom: 20px;

  .preview {
    width: 100%;
    height: 145px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .border {
      width: calc(100% - 12px);
      margin: 0 auto;
      height: 1px;
      background-color: #c4c4c4;
    }
  }

  .name {
    padding: 15px 11px 9px 12px;
    font-size: 16px;
    line-height: 19px;
    color: #1d262d;
    text-align: left;
  }

  &:nth-child(even) {
    margin-left: 27px;
    margin-right: 27px;
  }
}

.products-wrap {
  position: relative;
  left: -85px;
  width: calc(100% + 200px);
}

.products {
  .products-filters {
    max-width: 900px;
    width: 100%;

    .v-input--radio-group__input {
      justify-content: center;
    }

    .products-filters-item {
      border: 2px solid $grey-4;
      border-radius: 4px;

      .v-input--selection-controls__input {
        display: none;
      }

      span {
        color: $primary;
      }

      img {
        width: 19px;
      }

      &.is-active {
        border-color: $primary;
        background-color: $primary;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);

        span {
          color: #fff;
        }

        &:hover {
          background-color: lighten($primary, 10%);
        }
      }

      &:hover {
        background-color: rgba($primary, 0.05);
      }
    }
  }
}

@media screen and (max-width: 1439px) {
  .products-wrap {
    left: -77px;
    width: calc(100% + 185px);
  }
}

@media screen and (max-width: 600px) {
  .products .products-filters .products-filters-item {
    span {
      font-size: 12px;
    }
    img {
      width: 24px;
    }
  }
}

@media screen and (max-width: 475px) {
  .products .products-wrap .products-item {
    width: calc(50% - 12px);
    margin: 0 6px 12px;
    box-shadow: 0 4px 8px rgb(0 0 0 / 15%);

    span {
      padding: 12px 16px;
      font-size: 14px;
    }
  }
}

@media screen and (min-width: 1px) and (max-width: 767px) {
  .products-wrap {
    left: 0;
    width: 100%;
  }

  .product-card {
    width: 100px;

    .preview {
      height: 105px;
    }

    &:nth-child(even) {
      margin-left: 17px;
      margin-right: 17px;
    }
  }
}
</style>
